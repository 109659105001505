<template>
  <v-theme-provider>
    <base-section
      id="bannertwo"
      class="accent pt-1 pb-3"
    >
      <div class="banner-text white--text banner pl-10" />
    </base-section>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'SectionNews',
    provide: {
      heading: { align: 'center' },
    },
  }
</script>

<style>
.banner-text{
  font-weight: bold;
  font-size: 1.7rem;
  word-spacing: 3.4rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}
.banner{
  width:100%;
  height:29px;
  white-space: nowrap;
  overflow-x: hidden!important;
  font-size: calc(14px + (26 - 14) * ((100vw - 300px) / (1600 - 300)))!important;

}
.bg{
  color:#F0DEDE!important;
}
</style>
